import { ReactNode, createContext, useContext } from 'react';
import { useGeolocated } from 'react-geolocated';

interface GeolocatedResult {
  latitude: number;
  longitude: number;
}

const GeolocatedResultContext = createContext<GeolocatedResult | undefined>(
  undefined
);

export const useCurrentGeolocation = () => {
  return useContext(GeolocatedResultContext);
};

export const GeolocatedResultProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  return (
    <GeolocatedResultContext.Provider value={coords}>
      {children}
    </GeolocatedResultContext.Provider>
  );
};
