/* eslint-disable complexity */
import { addResults } from './reducer-utils';
import { ActionType, StoreLocatorState } from './types';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export const defaultState: StoreLocatorState = {
  rawResults: [],
  results: [],
  resultsIsLoading: false,
  pageIsLoading: false,
  suggestionsLoading: false,
  page: [],
  pageSize: appConfig.merchantRetrievalLimit,
  pageNumber: 1,
  industries: [],
  industry: 'ALL',
  searched: false,
  query: null,
  merchant: null,
  locationSuggestions: [],
  locationSuggestionsLoading: true,
  locationZipCode: '',
  locationCollapsed: true,
  keywordSuggestions: [],
  keyword: '',
  viewMode: 'IN_STORE_AND_OMNI_CHANNEL',
  merchantGroupList: [],
  showMerchantGroup: false,
  selectedGroupMerchant: null,
  browserGeoLocationAccess: true,
  totalMerchants: 10,
  onlineIndustriesLoading: false,
  error: false,
  showContentArea: true,
  hasMore: true,
  errorLoadingIndustries: false,
  errorLoadingMerchants: false,
};

export function StoreLocatorReducer(
  state = defaultState,
  action: ActionType
): StoreLocatorState {
  switch (action.type) {
    case 'SET_INDUSTRIES':
      return {
        ...state,
        industries: action.payload,
      };
    case 'SET_RESULTS_LOADING':
      return {
        ...state,
        resultsIsLoading: action.payload,
        error: false,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_PAGE_IS_LOADING':
      return {
        ...state,
        pageIsLoading: action.payload,
      };

    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: action.payload,
      };

    case 'SET_KEYWORD_SUGGESTIONS':
      return {
        ...state,
        keywordSuggestions: action.payload,
        suggestionsLoading: false,
      };

    case 'RESET_FILTERS':
      return {
        ...state,
        keyword: '',
      };

    case 'SET_MERCHANT':
      return {
        ...state,
        merchant: action.payload,
        showMerchantGroup: false,
        merchantGroupList: [],
      };

    case 'SET_KEYWORD':
      return {
        ...state,
        keyword: action.payload.keyword,
        suggestionsLoading: action.payload.suggestionsLoading,
      };

    case 'CLEAR_MERCHANT':
      return {
        ...state,
        merchant: null,
      };

    case 'SET_LOCATION_SUGGESTIONS':
      return {
        ...state,
        locationSuggestions: action.payload,
      };

    case 'SET_LOCATION_SUGGESTIONS_LOADING':
      return {
        ...state,
        locationSuggestionsLoading: action.payload,
      };

    case 'SET_LOCATION_COLLAPSED':
      return {
        ...state,
        locationCollapsed: action.payload,
      };

    case 'SET_VIEW_MODE':
      return {
        ...defaultState,
        viewMode: action.payload,
      };

    case 'RESET':
      return {
        ...defaultState,
      };

    case 'SET_MERCHANT_GROUP_LIST':
      return {
        ...state,
        merchantGroupList: action.payload,
      };

    case 'SET_SHOW_MERCHANT_GROUP':
      return {
        ...state,
        showMerchantGroup: action.payload,
      };

    case 'SET_SELECTED_GROUP_MERCHANT':
      return {
        ...state,
        selectedGroupMerchant: action.payload,
      };

    case 'BROWSER_GEO_LOCATION_ACCESS':
      return {
        ...state,
        browserGeoLocationAccess: action.payload,
      };

    case 'SET_ONLINE_INDUSTRIES_IS_LOADING':
      return {
        ...state,
        onlineIndustriesLoading: action.payload,
      };
    case 'TOGGLE_CONTENT_AREA':
      return {
        ...state,
        showContentArea: !state.showContentArea,
      };
    case 'ADD_RESULTS':
      return addResults(state, action.payload);

    case 'RESET_RESULTS':
      return {
        ...state,
        keyword: action?.payload?.keyword ?? state.keyword,
        results: [],
        rawResults: [],
        totalMerchants: 0,
        pageNumber: 1,
        hasMore: true,
        resultsIsLoading: true,
      };

    case 'SET_HAS_MORE':
      return {
        ...state,
        hasMore: action.payload,
      };

    case 'SET_INDUSTRY':
      return {
        ...state,
        industry: action.payload,
      };

    case 'INCREMENT_PAGE_NUMBER':
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
      };
    case 'ADD_LOCATION':
      return { ...state, ...action.payload };
    case 'SET_ERROR_LOADING_INDUSTRIES':
      return { ...state, errorLoadingIndustries: action.payload };
    case 'SET_ERROR_LOADING_MERCHANTS':
      return { ...state, errorLoadingMerchants: action.payload };
    default:
      return state;
  }
}
