import { useCallback } from 'react';
import {
  Location,
  Merchant,
  MerchantSearchResponse,
  IndustrySearchResponse,
  MerchantsQuery,
  IndustriesQuery,
  OnlineMerchantsQuery,
  StateDetails,
  MerchantBusinessDetail,
} from '@ee-monorepo/shared/utilities/types';
import * as Sentry from '@sentry/nextjs';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { useFetchWithErrorTracking } from '@ee-monorepo/shared/utilities/hooks';

import { getCookie } from '@ee-monorepo/shared/utilities/functions';

const DEFAULT_QUERY: MerchantsQuery = {
  distance: 25,
  includeKona: true,
  offset: 0,
  limit: appConfig.merchantRetrievalLimit,
};

const DEFAULT_INDUSTRIES_QUERY: IndustriesQuery = {
  distance: 25,
  includeKona: true,
};

const DEFAULT_ONLINE_MERCHANTS_QUERY: OnlineMerchantsQuery = {
  offset: 0,
  limit: appConfig.merchantRetrievalLimit,
};

const useStoreLocatorAPI = () => {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();

  const fetchMerchantsFallback = useCallback(
    async (query: MerchantsQuery): Promise<MerchantSearchResponse | null> => {
      const sentryDelayLogTimeout = setTimeout(
        () =>
          Sentry.captureMessage(
            'Store locator original endpoint took more than 8 seconds',
            'info'
          ),
        8000
      );
      const response = await fetchWithErrorTracking(
        `${appConfig.storeLocatorApi}/search/merchants`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...DEFAULT_QUERY, ...query }),
          handleErrorTrackingSeverity(httpStatus) {
            if (httpStatus === 404) return 'info';
            return 'error';
          },
        }
      );
      window.clearTimeout(sentryDelayLogTimeout);
      const merchants = await response.json();
      if (response.status !== 200 || merchants.error) {
        return null;
      } else {
        return merchants;
      }
    },
    [fetchWithErrorTracking]
  );

  const fetchMerchants = useCallback(
    async (query: MerchantsQuery): Promise<MerchantSearchResponse | null> => {
      const sentryDelayLogTimeout = setTimeout(
        () =>
          Sentry.captureMessage(
            'Store prioritizer taking more time to respond than expected',
            'info'
          ),
        3000
      );
      try {
        const response = await fetchWithErrorTracking(
          appConfig.storeLocatorApiExperimental +
            '/store-prioritizer/search/merchants',
          {
            method: 'POST',
            signal: AbortSignal.timeout?.(8000),
            headers: {
              'Content-Type': 'application/json',
              'api-key': appConfig.storeLocatorApiExperimentalApiKey || '',
              'analytics-session-storage':
                sessionStorage.getItem('analytics_session_storage') || '',
              'analytics-uuid': getCookie('analytics_uuid') || '',
              'analytics-session-cookie':
                getCookie('analytics_session_cookie') || '',
            },
            body: JSON.stringify({ ...DEFAULT_QUERY, ...query }),
            handleErrorTrackingSeverity(httpStatus) {
              if (httpStatus === 404) return 'info';
              return 'error';
            },
          }
        );
        window.clearTimeout(sentryDelayLogTimeout);
        const merchants = await response.json();
        if (![404, 200].includes(response?.status) || merchants.error) {
          throw new Error(
            `Store prioritizer failed status: ${response?.status}`
          );
        } else {
          return merchants;
        }
      } catch (e) {
        Sentry.withScope(function (scope) {
          scope.setTag('api', 'store-prioritizer');
          scope.setTransactionName('Store prioritizer failed');
          scope.setLevel('warning');
          Sentry.captureException(e);
        });
        const _merchants = await fetchMerchantsFallback(query);
        return _merchants;
      }
    },
    [fetchWithErrorTracking, fetchMerchantsFallback]
  );

  const fetchIndustries = useCallback(
    async (query: IndustriesQuery): Promise<IndustrySearchResponse | null> => {
      const response = await fetchWithErrorTracking(
        `${appConfig.storeLocatorApi}/search/merchants/industries`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...DEFAULT_INDUSTRIES_QUERY, ...query }),
        }
      );
      const industries = await response.json();
      if (response.status !== 200 || industries.error) {
        return null;
      } else {
        return industries;
      }
    },
    [fetchWithErrorTracking]
  );

  /**
   * Suggests locations based on zipcode or hint provided
   */
  const fetchAutocomplete = useCallback(
    async (hint: string): Promise<Location[]> => {
      const response = await fetchWithErrorTracking(
        `/api/merchant/autocomplete?hint=${hint}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const suggestions = await response.json();
      return suggestions.result;
    },
    [fetchWithErrorTracking]
  );

  const fetchOnlineMerchantsFallback = useCallback(
    async (
      query: OnlineMerchantsQuery
    ): Promise<MerchantSearchResponse | null> => {
      const response = await fetchWithErrorTracking(
        `${appConfig.storeLocatorApi}/search/merchants/ecommercePlatform`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...DEFAULT_ONLINE_MERCHANTS_QUERY, ...query }),
        }
      );
      const merchants = await response.json();
      if (response.status !== 200 || merchants.error) {
        return null;
      } else {
        return merchants;
      }
    },
    [fetchWithErrorTracking]
  );

  const fetchOnlineMerchants = useCallback(
    async (
      query: OnlineMerchantsQuery
    ): Promise<MerchantSearchResponse | null> => {
      const sentryDelayLogTimeout = setTimeout(
        () =>
          Sentry.captureMessage(
            'Store prioritizer taking more time to respond than expected for online stores',
            'info'
          ),
        3000
      );
      try {
        const response = await fetchWithErrorTracking(
          appConfig.storeLocatorApiExperimental +
            '/store-prioritizer/search/online_merchants',
          {
            method: 'POST',
            signal: AbortSignal.timeout?.(8000),
            headers: {
              'Content-Type': 'application/json',
              'api-key': appConfig.storeLocatorApiExperimentalApiKey || '',
              'analytics-session-storage':
                sessionStorage.getItem('analytics_session_storage') || '',
              'analytics-uuid': getCookie('analytics_uuid') || '',
              'analytics-session-cookie':
                getCookie('analytics_session_cookie') || '',
            },
            body: JSON.stringify({ ...DEFAULT_QUERY, ...query }),
            handleErrorTrackingSeverity(httpStatus) {
              if (httpStatus === 404) return 'info';
              return 'error';
            },
          }
        );
        window.clearTimeout(sentryDelayLogTimeout);
        const merchants = await response.json();
        if (![404, 200].includes(response?.status) || merchants.error) {
          throw new Error(
            `Store prioritizer failed status: ${response?.status}`
          );
        } else {
          return merchants;
        }
      } catch (e) {
        Sentry.withScope(function (scope) {
          scope.setTag('api', 'store-prioritizer');
          scope.setTransactionName(
            'Store prioritizer failed for online stores'
          );
          scope.setLevel('warning');
          Sentry.captureException(e);
        });
        const _merchants = await fetchOnlineMerchantsFallback(query);
        return _merchants;
      }
    },
    [fetchWithErrorTracking, fetchOnlineMerchantsFallback]
  );

  const fetchOnlineIndustries =
    useCallback(async (): Promise<IndustrySearchResponse | null> => {
      const response = await fetchWithErrorTracking(
        `${appConfig.storeLocatorApi}/search/merchants/ecommercePlatform/industries`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const industries = await response.json();
      if (response.status !== 200 || industries.error) {
        return null;
      } else {
        return industries;
      }
    }, [fetchWithErrorTracking]);

  const fetchMerchantGroup = useCallback(
    async (merchantGroup: number, zipCode: string): Promise<Merchant[]> => {
      const response = await fetchWithErrorTracking(
        `/api/merchant/merchantGroup?merchantGroup=${merchantGroup}&zipCode=${zipCode}`,
        {
          method: 'GET',
          handleErrorTrackingSeverity(httpStatus) {
            if (httpStatus === 404) {
              return 'info';
            }
            return 'error';
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const merchants = await response.json();
      if (response.status !== 200 || merchants.message) {
        return [];
      } else {
        merchants.forEach(
          (merchant: { formattedAddress: string; address: string }) => {
            merchant.formattedAddress = merchant.address;
          }
        );
        return merchants;
      }
    },
    [fetchWithErrorTracking]
  );

  /**
   * Detailed merchant information, like opening hours
   */
  const fetchMerchantDetails = useCallback(
    async (id: number): Promise<Merchant | null> => {
      const response = await fetchWithErrorTracking(
        `${appConfig.storeLocatorApi}/merchant/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          handleErrorTrackingSeverity(httpStatus) {
            if (httpStatus === 204) {
              // not found
              return 'info';
            }
            return 'error';
          },
        }
      );
      const merchant = await response.json();
      if (response.status !== 200 || merchant.error) {
        return null;
      } else {
        return merchant;
      }
    },
    [fetchWithErrorTracking]
  );

  /**
   * Detailed state information, like cities with zipcodes
   */
  const fetchStateDetails = useCallback(
    async (
      state: string,
      pageSize: number,
      pageNumber: number
    ): Promise<StateDetails | null> => {
      const queryParams = `?pageSize=${pageSize}&start=${pageNumber}`;
      const url = `${appConfig.storeLocatorApi}/merchant/states/${state}${queryParams}`;
      const response = await fetchWithErrorTracking(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const stateDetails = await response.json();
      if (response.status !== 200 || stateDetails.error) {
        return null;
      } else {
        return stateDetails;
      }
    },
    [fetchWithErrorTracking]
  );

  const fetchBusinessReviews = useCallback(
    async (
      merchantId,
      name,
      latitude,
      longitude,
      state,
      zipCode,
      streetAddress,
      city,
      merchantGroupName,
      phone
    ): Promise<MerchantBusinessDetail | undefined> => {
      const queryParams = `merchantId=${merchantId}&name=${name}&merchantGroupName=${merchantGroupName}&phone=${phone}&latitude=${latitude}&longitude=${longitude}&state=${state}&zipCode=${zipCode}&streetAddress=${streetAddress}&city=${city}`;
      const url = `/api/businessReviews?${queryParams}`;
      const response = await fetchWithErrorTracking(url, {
        method: 'GET',
        handleErrorTrackingSeverity(httpStatus) {
          if (httpStatus === 404) {
            return 'info';
          }
          return 'error';
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const businessReviews = await response.json();
      if (response.status !== 200 || businessReviews.error) {
        return undefined;
      } else {
        return businessReviews;
      }
    },
    [fetchWithErrorTracking]
  );

  return {
    fetchMerchants,
    fetchOnlineMerchants,
    fetchAutocomplete,
    fetchMerchantGroup,
    fetchMerchantDetails,
    fetchIndustries,
    fetchOnlineIndustries,
    fetchStateDetails,
    fetchBusinessReviews,
  };
};

export { useStoreLocatorAPI };
