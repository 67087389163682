import { createContext, useContext } from 'react';
import { MerchantBusinessDetailsContextProps } from './types';

export const MerchantBusinessDetailsContext =
  createContext<MerchantBusinessDetailsContextProps>({
    loading: false,
    data: {
      businessId: '',
      merchantId: '',
      imageUrl: '',
      url: '',
      reviewCount: 0,
      rating: 0,
      reviews: [],
    },
  });

export const useMerchantBusinessDetailsContext = () => {
  const merchantBusinessDetails = useContext(MerchantBusinessDetailsContext);
  return merchantBusinessDetails;
};
