import {
  Industry,
  Merchant,
  MerchantSearchResponse,
  MerchantsQuery,
} from '@ee-monorepo/shared/utilities/types';
import { StoreLocatorState } from './types';

export function addResults(
  state: StoreLocatorState,
  payload: MerchantsQuery & MerchantSearchResponse
): StoreLocatorState {
  let resultsCity = '';
  let resultsState = '';
  if (!!payload && !!payload.merchants) {
    if (!state.results.length && payload.merchants.length) {
      resultsCity = payload.merchants[0].city;
      resultsState = payload.merchants[0].state;
    }
    let newState: StoreLocatorState = {
      ...state,
      rawResults: [...state.rawResults, ...payload.merchants],
      results: [...state.results, ...payload.merchants],
      totalMerchants: payload.totalResults,
      keywordSuggestions: [],
      query: {
        userCoordinates: payload.userCoordinates,
        zipCode: payload.zipCode,
        offset: payload.offset,
        limit: payload.limit,
      },
      industry: (payload.industry || 'ALL') as Industry,
      searched: true,
    };
    if (!state.results.length) {
      newState = {
        ...newState,
        state: resultsState,
        city: resultsCity,
      };
    }
    return newState;
  }
  return {
    ...state,
    searched: true,
  };
}

/* This function is used for suggestions or for results filtering: only difference, sort:false from suggestions and true for results */
export function filterByKeyword(
  state: StoreLocatorState,
  keyword: string,
  sort = false
): Merchant[] {
  const dbaNameMatches = [],
    dbaNamePartials = [],
    dbaNameReversePartials = [],
    industryResults = [],
    searchTagResults = [],
    searchTerm = keyword.toLowerCase();

  state.rawResults.forEach(function (merchant) {
    const searchTags = merchant.searchTags || [],
      dbaName = merchant?.dbaName?.toLowerCase(),
      industry = merchant?.industry?.toLowerCase();

    if (!dbaName) {
      return;
    }
    if (dbaName === searchTerm) {
      //exact name matches
      dbaNameMatches.push(merchant);
      return;
    }

    //partial name matches (e.g. searchTerm: 'furniture' finds dbaName: 'furniture warehouse')
    if (dbaName.indexOf(searchTerm) >= 0) {
      dbaNamePartials.push(merchant);
      return;
    }

    //reverse partial name matches (e.g. searchTerm: 'IKEA store' still finds dbaName: 'IKEA')
    if (searchTerm.indexOf(dbaName) >= 0) {
      dbaNameReversePartials.push(merchant);
      return;
    }

    //industry results (e.g. searchTerm: 'rim' finds industry: 'tires_rims')
    if (industry.indexOf(searchTerm) >= 0) {
      industryResults.push(merchant);
      return;
    }

    //search-tag results (e.g. searchTerm: 'couch' still finds dbaName: 'sit on it' through search tag 'couch')
    for (let i = 0; i < searchTags.length; i++) {
      const searchTag = searchTags[i].toLowerCase();
      if (
        searchTag.indexOf(searchTerm) >= 0 ||
        searchTerm.indexOf(searchTag) >= 0
      ) {
        searchTagResults.push(merchant);
        break;
      }
    }
  });

  if (sort) {
    [
      dbaNameMatches,
      dbaNamePartials,
      dbaNameReversePartials,
      industryResults,
      searchTagResults,
    ].forEach(function (array) {
      array.sort(function (a, b) {
        //ignore distance for ecommerce platform merchants
        if (a.ecommercePlatform || b.ecommercePlatform) {
          return a.ranking - b.ranking;
        }

        return a.distance - b.distance || a.ranking - b.ranking;
      });
    });
  }

  const filteredArray = dbaNameMatches.concat(
    dbaNamePartials,
    dbaNameReversePartials,
    industryResults,
    searchTagResults
  );

  return sort
    ? //move priority merchants to the top of the list and sort by proximity then ranking
      filteredArray.sort(function (a, b) {
        return b.searchPriority - a.searchPriority;
      })
    : filteredArray;
}
