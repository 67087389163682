import { StoreLocatorState } from './types';
import { categorizedIndustries } from './constants';
import { Merchant } from '@ee-monorepo/shared/utilities/types';

export const selectHasResults = ({ results }: StoreLocatorState) =>
  results.length > 0;

export const selectResultsCity = ({ city }: StoreLocatorState) => city;

export const selectResultsState = ({ state }: StoreLocatorState) => state;

export const selectWasSearched = ({ searched }: StoreLocatorState) => searched;

export const selectResultsIndustries = ({ industries }: StoreLocatorState) =>
  industries;

export const selectIndustry = ({ industry }: StoreLocatorState) => industry;

export const selectMerchant = ({ merchant }: StoreLocatorState) => merchant;

export const error = ({ error }: StoreLocatorState) => error;

export const selectLocationSuggestions = ({
  locationSuggestions,
}: StoreLocatorState) => locationSuggestions;

export const selectLocationSuggestionsLoading = ({
  locationSuggestionsLoading,
}: StoreLocatorState) => locationSuggestionsLoading;

export const selectLocationZipCode = ({ locationZipCode }: StoreLocatorState) =>
  locationZipCode;

export const selectKeywordSuggestions = ({
  keywordSuggestions,
}: StoreLocatorState) => keywordSuggestions;

export const selectSuggestionsLoading = ({
  suggestionsLoading,
}: StoreLocatorState) => suggestionsLoading;

export const selectKeyword = ({ keyword }: StoreLocatorState) => keyword;

export const selectViewMode = ({ viewMode }: StoreLocatorState) => viewMode;

export const selectMerchantGroupList = ({
  merchantGroupList,
}: StoreLocatorState) => merchantGroupList;

export const selectShowMerchantGroup = ({
  showMerchantGroup,
}: StoreLocatorState) => showMerchantGroup;

export const selectSelectedGroupMerchant = ({
  selectedGroupMerchant,
}: StoreLocatorState) => selectedGroupMerchant;

export const selectMerchantLocations = ({
  results,
  showMerchantGroup,
  merchantGroupList,
  merchant,
}: StoreLocatorState) => {
  if (!showMerchantGroup) {
    return buildMerchantLocations([
      ...results.filter((r) => r.id !== (merchant && merchant.id)),
      {
        ...merchant,
      },
    ]);
  } else {
    return buildMerchantLocations(merchantGroupList);
  }
};

const buildMerchantLocations = (merchants: Merchant[]) => {
  if (merchants.length > 0) {
    return merchants
      .filter((merchant) => merchant.industry !== 'ALL')
      .filter((merchant) => !!merchant.location)
      .map((merchant) => ({
        id: merchant.id,
        latitude: merchant.location?.latitude,
        longitude: merchant.location?.longitude,
        category:
          categorizedIndustries.findIndex(
            (categorizedIndustry) => categorizedIndustry === merchant.industry
          ) > -1
            ? merchant.industry
            : 'OTHER',
        dbaName: merchant.dbaName,
        address: merchant.formattedAddress,
      }));
  }
  return [];
};

export const selectResults = ({ results }: StoreLocatorState) => results;
export const selectMerchantsCount = ({ totalMerchants }: StoreLocatorState) =>
  totalMerchants;
export const selectPageSize = ({ pageSize }: StoreLocatorState) => pageSize;
export const selectPageNumber = ({ pageNumber }: StoreLocatorState) =>
  pageNumber;
export const selectHasMore = ({ hasMore }: StoreLocatorState) => hasMore;

export const resultsStatus = ({ resultsIsLoading }: StoreLocatorState) =>
  resultsIsLoading;

export const pageLoadingStatus = ({ pageIsLoading }: StoreLocatorState) =>
  pageIsLoading;

export const onlineIndustriesLoadingStatus = ({
  onlineIndustriesLoading,
}: StoreLocatorState) => onlineIndustriesLoading;

export const isLocationAllowedByUser = ({
  browserGeoLocationAccess,
}: StoreLocatorState) => browserGeoLocationAccess;

export const selectIsContentAreaVisible = ({
  showContentArea,
}: StoreLocatorState) => showContentArea;

export const selectErrorLoadingIndustries = ({
  errorLoadingIndustries,
}: StoreLocatorState) => errorLoadingIndustries;

export const selectErrorLoadingMerchants = ({
  errorLoadingMerchants,
}: StoreLocatorState) => errorLoadingMerchants;
