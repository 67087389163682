import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useDispatch } from './store';
import { ViewMode } from './types';
import {
  Merchant,
  MerchantsQuery,
  MerchantIndustry,
  IndustriesQuery,
  OnlineMerchantsQuery,
  Industry,
} from '@ee-monorepo/shared/utilities/types';
import {
  selectHasMore,
  selectIndustry,
  selectPageNumber,
  selectResultsIndustries,
  selectSelectedGroupMerchant,
  selectViewMode,
} from './selectors';
import { useStoreLocatorAPI } from './useStoreLocatorAPI';
import { useStoreLocatorRouterActions } from './useStoreLocatorRouterActions';
import { buildOffsetUsingPageNumber } from '@ee-monorepo/shared/utilities/functions';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

const useStoreLocatorActions = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const {
    fetchMerchants,
    fetchOnlineMerchants,
    fetchAutocomplete,
    fetchMerchantDetails,
    fetchIndustries,
    fetchOnlineIndustries,
  } = useStoreLocatorAPI();
  const selectedGroupMerchant = useSelector(selectSelectedGroupMerchant);
  const viewMode = useSelector(selectViewMode);

  const { pushMerchantDetails } = useStoreLocatorRouterActions();

  const searchIndustries = useCallback(
    async (query: IndustriesQuery) => {
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: true,
      });
      try {
        const searchResponseIndustries = await fetchIndustries(query);
        dispatch({
          type: 'SET_INDUSTRIES',
          payload: searchResponseIndustries.industries,
        });
      } catch {
        dispatch({
          type: 'SET_ERROR',
          payload: true,
        });
        dispatch({
          type: 'SET_ERROR_LOADING_INDUSTRIES',
          payload: true,
        });
      }
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: false,
      });
    },
    [dispatch, fetchIndustries]
  );

  const searchMerchants = useCallback(
    async (query: MerchantsQuery) => {
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: true,
      });
      try {
        const searchResponse = await fetchMerchants(query);
        if (searchResponse?.merchants.length === 0) {
          dispatch({ type: 'SET_HAS_MORE', payload: false });
        } else {
          dispatch({
            type: 'ADD_RESULTS',
            payload: {
              ...searchResponse,
              ...query,
              industry: query.industry,
            },
          });
        }
      } catch {
        dispatch({
          type: 'SET_ERROR',
          payload: true,
        });
        dispatch({
          type: 'SET_ERROR_LOADING_MERCHANTS',
          payload: true,
        });
      }
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: false,
      });
    },
    [dispatch, fetchMerchants]
  );

  /**
   * Funtion that search for merchants only to get its city and state and loads them in the redux instance
   */
  const searchMerchantsForLocation = useCallback(
    async (query: MerchantsQuery) => {
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: true,
      });
      try {
        const searchResponse = await fetchMerchants(query);
        dispatch({
          type: 'ADD_LOCATION',
          payload: {
            city: searchResponse?.merchants[0].city || '',
            state: searchResponse?.merchants[0].state || '',
          },
        });
      } catch {
        console.log('Error loading city and state');
      }
      dispatch({
        type: 'SET_RESULTS_LOADING',
        payload: false,
      });
    },
    [dispatch, fetchMerchants]
  );

  const searchOnlineIndustries = useCallback(async () => {
    dispatch({ type: 'SET_ONLINE_INDUSTRIES_IS_LOADING', payload: true });
    const searchResponseIndustries = await fetchOnlineIndustries();
    dispatch({
      type: 'SET_INDUSTRIES',
      payload: searchResponseIndustries.industries,
    });
    dispatch({ type: 'SET_ONLINE_INDUSTRIES_IS_LOADING', payload: false });
  }, [dispatch, fetchOnlineIndustries]);

  const loadOnlineMerchants = useCallback(
    async (query: OnlineMerchantsQuery) => {
      dispatch({ type: 'SET_PAGE_IS_LOADING', payload: true });
      const searchResponse = await fetchOnlineMerchants(query);
      if (searchResponse?.merchants.length === 0) {
        dispatch({ type: 'SET_HAS_MORE', payload: false });
      } else {
        dispatch({
          type: 'ADD_RESULTS',
          payload: { ...searchResponse, ...query, industry: query.industry },
        });
      }
      dispatch({ type: 'SET_PAGE_IS_LOADING', payload: false });
    },
    [dispatch, fetchOnlineMerchants]
  );

  const setPageNumber = useCallback(
    async (pageNumber: number) => {
      dispatch({ type: 'SET_PAGE_NUMBER', payload: pageNumber });
    },
    [dispatch]
  );

  const incrementPageNumber = useCallback(async () => {
    dispatch({ type: 'INCREMENT_PAGE_NUMBER' });
  }, [dispatch]);

  const setIndustries = useCallback(
    async (industries: MerchantIndustry[]) => {
      dispatch({ type: 'SET_INDUSTRIES', payload: industries });
    },
    [dispatch]
  );

  const setIndustry = useCallback(
    async (industry: Industry) => {
      dispatch({ type: 'SET_INDUSTRY', payload: industry });
    },
    [dispatch]
  );

  const setKeyword = useCallback(
    async (keyword: string) => {
      dispatch({
        type: 'SET_KEYWORD',
        payload: { keyword, suggestionsLoading: true },
      });
    },
    [dispatch]
  );

  const setMerchant = useCallback(
    async (merchant: Merchant) => {
      dispatch({ type: 'SET_MERCHANT', payload: merchant });
    },
    [dispatch]
  );

  const suggestMerchantsLocations = useCallback(
    async (hint: string) => {
      dispatch({ type: 'SET_LOCATION_SUGGESTIONS_LOADING', payload: true });
      const result = await fetchAutocomplete(hint);
      dispatch({
        type: 'SET_LOCATION_SUGGESTIONS',
        payload: result,
      });
      dispatch({ type: 'SET_LOCATION_SUGGESTIONS_LOADING', payload: false });
    },
    [dispatch, fetchAutocomplete]
  );

  const suggestByKeyword = useCallback(
    async (query: MerchantsQuery, viewMode: ViewMode) => {
      let suggestions: Merchant[] = [];
      const pagination = { offset: 0, limit: 5 };
      const industry =
        (query.industry as Industry) === 'ALL' ? '' : query.industry;
      if (viewMode === 'ONLINE') {
        const response = await fetchOnlineMerchants({
          industry,
          keyWord: query.keyWord,
          ...pagination,
        });
        suggestions = response?.merchants || [];
      } else {
        const response = await fetchMerchants({
          ...query,
          industry,
          ...pagination,
        });
        suggestions = response?.merchants || [];
      }
      dispatch({ type: 'SET_KEYWORD_SUGGESTIONS', payload: suggestions || [] });
    },
    [dispatch, fetchMerchants, fetchOnlineMerchants]
  );

  const loadResultsByKeyword = useCallback(
    async (keyword: string) => {
      dispatch({ type: 'SET_RESULTS_BY_KEYWORD', payload: keyword });
    },
    [dispatch]
  );

  const setViewMode = useCallback(
    async (keyword: ViewMode) => {
      dispatch({ type: 'SET_VIEW_MODE', payload: keyword });
    },
    [dispatch]
  );

  const clear = useCallback(async () => {
    dispatch({ type: 'RESET' });
  }, [dispatch]);

  const setMerchantGroupList = useCallback(
    async (merchantGroupList: Merchant[]) => {
      dispatch({ type: 'SET_MERCHANT_GROUP_LIST', payload: merchantGroupList });
    },
    [dispatch]
  );

  const setShowMerchantGroup = useCallback(
    async (isShowMerchantGroup: boolean) => {
      dispatch({
        type: 'SET_SHOW_MERCHANT_GROUP',
        payload: isShowMerchantGroup,
      });
    },
    [dispatch]
  );

  const setSelectedGroupMerchant = useCallback(
    async (selectedGroupMerchant: Merchant) => {
      dispatch({
        type: 'SET_SELECTED_GROUP_MERCHANT',
        payload: selectedGroupMerchant,
      });
    },
    [dispatch]
  );

  const loadSingleMerchantAndResults = useCallback(
    async (merchantId: string, setSelectedLocation?: (id: number) => void) => {
      const merchant = await fetchMerchantDetails(parseInt(merchantId));
      if (merchant) {
        // do not add update zip code for any merchant card clicks and for online stores
        // add zipcode exclusively when merchant detail url - /findstore/merchantId=xxx hits with out location or zipcode.
        if (
          !router.query['zipCode'] &&
          !router.query['latitude'] &&
          !router.query['longitude'] &&
          viewMode === 'IN_STORE_AND_OMNI_CHANNEL'
        ) {
          pushMerchantDetails(merchant);
        }
        dispatch({
          type: 'SET_MERCHANT',
          payload: merchant,
        });
        setSelectedLocation?.(merchant.id);
      } else if (selectedGroupMerchant) {
        // when group merchant api call pass and merchant api call fails, use group merchant data to show merchant data
        dispatch({
          type: 'SET_MERCHANT',
          payload: selectedGroupMerchant,
        });
        // should not show other locations when merchant detail api call fail since group call cannot happen as merchant id is not changed
        setMerchantGroupList([]);
        setSelectedGroupMerchant(null);
      } else {
        // redirect to stores pages when given merchant id is not found
        if (viewMode === 'IN_STORE_AND_OMNI_CHANNEL') {
          router.push('/find-stores');
        } else {
          router.push('/online-stores');
        }
      }
    },
    [
      dispatch,
      fetchMerchantDetails,
      router,
      pushMerchantDetails,
      selectedGroupMerchant,
      setMerchantGroupList,
      setSelectedGroupMerchant,
      viewMode,
    ]
  );

  const toggleContentArea = useCallback(async () => {
    dispatch({
      type: 'TOGGLE_CONTENT_AREA',
    });
  }, [dispatch]);

  const {
    zipCode,
    zip,
    latitude,
    longitude,
    search: keyWord,
  } = (router.query as {
    [key: string]: string;
  }) || {};

  const zipParam = zipCode || zip;

  const pageNumber = useSelector(selectPageNumber);
  const industry = useSelector(selectIndustry);

  const callSearchMerchants = useCallback(() => {
    if (pageNumber !== 1) {
      if (latitude && longitude) {
        searchMerchants({
          userCoordinates: {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
          },
          offset: buildOffsetUsingPageNumber(String(pageNumber)),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      } else if (zipParam) {
        searchMerchants({
          zipCode: zipParam,
          offset: buildOffsetUsingPageNumber(String(pageNumber)),
          limit: appConfig.merchantRetrievalLimit,
          industry:
            industry !== 'ALL' ? (industry?.toUpperCase() as Industry) : '',
          keyWord,
        });
      }
    }
  }, [
    industry,
    keyWord,
    latitude,
    longitude,
    pageNumber,
    searchMerchants,
    zipParam,
  ]);

  const industries = useSelector(selectResultsIndustries);

  const hasMore = useSelector(selectHasMore);

  const callLoadOnlineMerchants = useCallback(() => {
    if (industries?.length > 0 && hasMore && pageNumber !== 1) {
      loadOnlineMerchants({
        industry: industry !== 'ALL' ? industry : '',
        keyWord,
        offset: buildOffsetUsingPageNumber(String(pageNumber)),
        limit: appConfig.merchantRetrievalLimit,
      });
    }
  }, [
    hasMore,
    industries?.length,
    industry,
    keyWord,
    loadOnlineMerchants,
    pageNumber,
  ]);

  return {
    searchMerchants,
    searchIndustries,
    setMerchant,
    setPageNumber,
    incrementPageNumber,
    loadOnlineMerchants,
    suggestMerchantsLocations,
    suggestByKeyword,
    loadResultsByKeyword,
    setKeyword,
    setViewMode,
    clear,
    setMerchantGroupList,
    setShowMerchantGroup,
    setSelectedGroupMerchant,
    loadSingleMerchantAndResults,
    searchOnlineIndustries,
    setIndustries,
    toggleContentArea,
    setIndustry,
    callSearchMerchants,
    callLoadOnlineMerchants,
    searchMerchantsForLocation,
  };
};

export { useStoreLocatorActions };
