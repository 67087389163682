import { configureStore, Dispatch, Store } from '@reduxjs/toolkit';
import { useDispatch as useDispatchRedux } from 'react-redux';
import { StoreLocatorReducer } from './reducer';
import { ActionType, StoreLocatorState } from './types';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export const store: Store<StoreLocatorState, ActionType> = configureStore({
  reducer: StoreLocatorReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: appConfig.disableReduxMiddleware ? false : true,
      serializableCheck: appConfig.disableReduxMiddleware ? false : true,
    }),
});

export function useDispatch() {
  const dispatch: Dispatch<ActionType> = useDispatchRedux();
  return dispatch;
}

export * from './selectors';
