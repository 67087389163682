import { Industry } from '@ee-monorepo/shared/utilities/types';

export const categorizedIndustries: Industry[] = [
  'APPLIANCES',
  'AUTO_SERVICE',
  'CAR_AUDIO',
  'COLLISION_REPAIR',
  'CELL_PHONES',
  'ELECTRONICS',
  'FURNITURE',
  'HOME_AUTO_ALARMS',
  'HOME_DECOR',
  'HVAC',
  'JEWELRY',
  'LAWN_AND_GARDEN',
  'MATTRESSES',
  'MEDICAL_DEVICES',
  'MUSICAL_INSTRUMENTS',
  'POWER_TOOLS',
  'SCOOTERS',
  'SPORTING_GOODS',
  'TIRES_RIMS',
  'COLLISION_REPAIR',
];
