import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreLocatorAPI } from './useStoreLocatorAPI';
import { MerchantBusinessDetailsContext } from './useMerchantBusinessDetails';
import { getMerchantFlows } from '@ee-monorepo/shared/utilities/hooks';
import { MerchantInterface } from '@ee-monorepo/shared/utilities/types';
import { selectViewMode } from './selectors';
import { MerchantBusinessDetailsContextProps } from './types';
import { useExperiment } from '@ee-monorepo/shared/data-access/experiments';
import { unformatPhone } from '@ee-monorepo/shared/utilities/functions';

export interface MerchantBusinessProviderProps {
  merchant: MerchantInterface;
  children: ReactNode;
}

export function MerchantBusinessProvider({
  merchant,
  children,
}: MerchantBusinessProviderProps) {
  const getPhoneNumberParameter = (phoneNumber: string) => {
    return `+1${unformatPhone(phoneNumber)}`;
  };
  const viewMode = useSelector(selectViewMode);
  const isOnlineView = viewMode === 'ONLINE';
  const { fetchBusinessReviews } = useStoreLocatorAPI();
  const [businessDetail, setBusinessDetail] =
    useState<MerchantBusinessDetailsContextProps>();
  const { experiment } = useExperiment('com-store-locator-yelp-integration');
  useEffect(() => {
    const merchatFlows = getMerchantFlows(merchant);
    async function fetchMyAPI() {
      setBusinessDetail({
        loading: true,
      });
      const data = await fetchBusinessReviews(
        merchant?.id,
        merchant?.dbaName,
        merchant?.location?.latitude,
        merchant?.location?.longitude,
        merchant?.state,
        merchant?.zipCode,
        merchant?.streetAddress,
        merchant?.city,
        merchant?.merchantGroupName,
        merchant?.phoneNumber && getPhoneNumberParameter(merchant?.phoneNumber)
      );
      setBusinessDetail({ loading: false, data });
    }
    !merchatFlows?.isInStoreLTODCX &&
      !isOnlineView &&
      experiment === 'on' &&
      fetchMyAPI();
    return () => {
      setBusinessDetail(undefined);
    };
  }, [
    merchant,
    fetchBusinessReviews,
    setBusinessDetail,
    isOnlineView,
    experiment,
  ]);
  return (
    <MerchantBusinessDetailsContext.Provider
      value={businessDetail as MerchantBusinessDetailsContextProps}
    >
      {children}
    </MerchantBusinessDetailsContext.Provider>
  );
}
